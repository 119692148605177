<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div
            class="d-flex justify-content-between align-items-center px-1 py-50"
          >
            <div class="col-6">
              <h3 class="m-0">
                {{ "Maquinas" }}
              </h3>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <b-link :to="{ name: 'createMachine' }">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mr-1 text-nowrap"
                  >
                    {{ "Añadir nueva máquina" }}
                  </b-button>
                </b-link>
              </div>
              <b-input-group class="input-group">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchTerm"
                  type="text"
                  class="d-inline-block"
                  :placeholder="'Búsqueda'"
                  @keydown.enter.native="handleSearch"
                />
                <b-button
                  variant="primary"
                  size="sm"
                  class="mr-1 ml-1"
                  @click="handleSearch"
                >
                  {{ "Buscar" }}
                </b-button>
              </b-input-group>
            </div>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
          >
            <template #cell(active)="data">
              <b-badge :variant="statusVariant(data.item.active)">
                {{ statusVariantText(data.item.active) }}
              </b-badge>
            </template>
            <template #cell(description)="data">
              <div v-if="data.item.description== '' || data.item.description == null || data.item.description == 'null' || data.item.description == '<p>null</p>'">
                -
              </div>
              <div v-else v-html="data.item.description" ></div>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'editMachine', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteMachine(data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <div
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BCard,
  BTable,
  BCol,
  BRow,
  BLink,
  BBadge,
  BCollapse,
  BFormGroup,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import { FlatPickrToTimestamp } from '@/libs/helpers'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { config } from '@/shared/app.config'
import axios from 'axios'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
  },
  data() {
    return {
      download: true,
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      columns: [
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Descripción',
          key: 'description',
        },
        {
          label: 'Estado',
          key: 'active',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
      date_ini: '',
      date_fin: '',
      project: '',
      visibleFilter: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'machines/getItems',
      totalItems: 'machines/getTotalItems',
      selectProjects: 'projects/getSelectProjects',
    }),
    statusVariant() {
      const statusColor = {
        1: 'light-success',
        0: 'light-danger',
      }
      return status => statusColor[status]
    },
    statusVariantText() {
      const statusText = {
        1: 'Activo',
        0: 'Desactivado',
      }
      return status => statusText[status]
    },
    projectProp() {
      if (this.project) {
        return this.project.id
      }
      return ''
    },
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    project() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.chargeData()
    },
    date_fin() {
      this.chargeData()
    },
    orderby() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  created() {
    this.chargeData()
    this.chargeProjects()
  },
  methods: {
    ...mapActions({
      list: 'machines/getListMachines',
      delete: 'machines/delete',
      getSelectProjects: 'projects/getSelectProjects',
    }),
    ...mapMutations({
      loading: 'app/loading',
    }),
    chargeProjects() {
      let client = ''
      if (this.client) {
        client = this.client.id
      }
      this.getSelectProjects({ client })
    },
    chargeData() {
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: '',
        date_fin: '',
        project: '',
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
    deleteMachine(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
